import * as React from 'react'

export interface DashboardIconProps {
  className?: string
}

const DashboardIcon: React.FCC<DashboardIconProps> = ({ className }) => {
  return (
    // prettier-ignore
    <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="17.3333" r="2.66667" stroke="#B8C6D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17.9333 15.4L20.6667 12.6666" stroke="#B8C6D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.53335 26.6667C4.53833 23.4913 2.98862 18.1366 4.67021 13.3184C6.3518 8.5002 10.8968 5.27258 16 5.27258C21.1032 5.27258 25.6482 8.5002 27.3298 13.3184C29.0114 18.1366 27.4617 23.4913 23.4667 26.6667H8.53335Z" stroke="#B8C6D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default DashboardIcon
