import * as React from 'react'

export interface AlertIconProps {
  className?: string
}

const AlertIcon: React.FCC<AlertIconProps> = ({ className }) => {
  return (
    // prettier-ignore
    <svg className={className} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="40" cy="40" r="30" stroke="#3085E8" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M39.9997 26.6667V40" stroke="#FFA526" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M39.9996 53.3334H40.0329" stroke="#FFA526" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default AlertIcon
