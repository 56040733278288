import React from 'react'

interface PersonalProps {
  className?: string
}

function Personal({ className }: PersonalProps) {
  return (
    <svg fill="none" viewBox="0 0 34 40" className={className}>
      <ellipse
        cx="16.167"
        cy="9.333"
        stroke="#062840"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.667"
        rx="5.333"
        ry="5.333"
      ></ellipse>
      <path
        stroke="#062840"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.667"
        d="M8.167 28v-3.733c0-4.124 2.387-7.467 5.333-7.467h5.333c1.246 0 2.392.598 3.3 1.6.151.167.296.346.434.534"
      ></path>
      <path
        stroke="#3085E8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.667"
        d="M21 24.167h-1.667c-.92 0-1.666.746-1.666 1.666v10c0 .92.746 1.667 1.666 1.667h8.334c.92 0 1.666-.746 1.666-1.667v-10c0-.92-.746-1.666-1.666-1.666H26"
      ></path>
      <rect
        width="5"
        height="3.333"
        x="21"
        y="22.5"
        stroke="#3085E8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.667"
        rx="1.667"
      ></rect>
      <path
        stroke="#3085E8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.667"
        d="M25.167 29.167h-2.084a1.25 1.25 0 100 2.5h.834a1.25 1.25 0 010 2.5h-2.084"
      ></path>
      <path
        fill="#3085E8"
        d="M24.333 34.167a.833.833 0 10-1.666 0h1.666zM22.667 35a.833.833 0 001.666 0h-1.666zm1.666-6.667a.833.833 0 00-1.666 0h1.666zm-1.666.834a.833.833 0 001.666 0h-1.666zm0 5V35h1.666v-.833h-1.666zm0-5.834v.834h1.666v-.834h-1.666z"
      ></path>
    </svg>
  )
}

export default Personal
