import * as React from 'react'

export interface MailboxIconProps {
  className?: string
}

const MailboxIcon: React.FCC<MailboxIconProps> = ({ className }) => {
  return (
    // prettier-ignore
    <svg className={className} width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 4H20V8" stroke="#263339" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14 10L20 4" stroke="#263339" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8 20H4V16" stroke="#263339" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4 20L10 14" stroke="#263339" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16 20H20V16" stroke="#263339" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14 14L20 20" stroke="#263339" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8 4H4V8" stroke="#263339" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4 4L10 10" stroke="#263339" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default MailboxIcon
