import * as React from 'react'

export interface ClipboardSearchIconProps {
  className?: string
}

const ClipboardSearchIcon: React.FCC<ClipboardSearchIconProps> = ({
  className,
}) => {
  return (
    // prettier-ignore
    <svg className={className} viewBox="0 0 32 32" fill="none" stroke="currentColor">
      <path d="M10.6673 6.66699H8.00065C7.29341 6.66699 6.61513 6.94794 6.11503 7.44804C5.61494 7.94814 5.33398 8.62641 5.33398 9.33366V25.3337C5.33398 26.0409 5.61494 26.7192 6.11503 27.2193C6.61513 27.7194 7.29341 28.0003 8.00065 28.0003H15.5967"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M23.9993 16.0003V9.33366C23.9993 8.62641 23.7184 7.94814 23.2183 7.44804C22.7182 6.94794 22.0399 6.66699 21.3327 6.66699H18.666"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.9993 4H13.3327C11.8599 4 10.666 5.19391 10.666 6.66667C10.666 8.13943 11.8599 9.33333 13.3327 9.33333H15.9993C17.4721 9.33333 18.666 8.13943 18.666 6.66667C18.666 5.19391 17.4721 4 15.9993 4Z"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.666 14.667H15.9993"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.666 20H14.666"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M21.9993 26.6667C23.8403 26.6667 25.3327 25.1743 25.3327 23.3333C25.3327 21.4924 23.8403 20 21.9993 20C20.1584 20 18.666 21.4924 18.666 23.3333C18.666 25.1743 20.1584 26.6667 21.9993 26.6667Z"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M24.666 26L27.9993 29.3333"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default ClipboardSearchIcon
