import * as React from 'react'

export interface QuoteIconProps {
  className?: string
}

const QuoteIcon: React.FCC<QuoteIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 71 55"
      fill="none"
      stroke="currentColor"
    >
      <path
        d="M59.2135 1.7209L59.2051 1.72586L59.1967 1.73098C44.6311 10.6869 37.1481 21.7021 37.1481 34.7957C37.1481 40.3369 38.7154 44.8769 41.9378 48.3072C45.133 51.7085 49.038 53.4326 53.5986 53.4326C58.3087 53.4326 62.182 51.8856 65.1159 48.7422L65.1167 48.7413C67.9476 45.7006 69.3203 41.6971 69.3203 36.8363C69.3203 32.677 68.252 29.1841 66.0373 26.4421C63.8314 23.711 61.08 22.2807 57.8256 22.2807C57.3766 22.2807 57.2516 22.1442 57.173 22.0002C57.0365 21.7497 56.9307 21.2109 57.0673 20.2091L57.0731 20.1667L57.0753 20.1239C57.1624 18.3819 57.7241 16.7694 58.7801 15.2692C59.9049 13.7719 61.995 11.4403 65.0853 8.25276C65.8899 7.49865 66.4051 6.58914 66.4051 5.53447C66.4051 4.49635 65.9044 3.52969 65.1374 2.65313L65.1155 2.62807L65.0919 2.60452C64.2595 1.77215 63.3231 1.21123 62.2799 1.04822C61.2274 0.883769 60.1938 1.14426 59.2135 1.7209ZM23.0651 1.72089L23.0567 1.72585L23.0483 1.73097C8.48272 10.6869 0.999698 21.702 0.999696 34.7957C0.999696 40.3369 2.56699 44.8769 5.78939 48.3072C8.98457 51.7085 12.8896 53.4326 17.4502 53.4326C22.1603 53.4326 26.0336 51.8856 28.9675 48.7421L28.9683 48.7412C31.7992 45.7006 33.1719 41.6971 33.1719 36.8363C33.1719 32.7663 32.0998 29.2843 29.9003 26.4565L29.8947 26.4492L29.8889 26.4421C27.683 23.711 24.9316 22.2807 21.6772 22.2807C21.2293 22.2807 21.0729 22.1424 20.9713 21.9703C20.8155 21.7067 20.6881 21.162 20.7778 20.1691C20.9545 18.4098 21.5675 16.7808 22.6317 15.2692C23.7579 13.77 25.8521 11.4342 28.949 8.24024C29.7435 7.44391 30.2567 6.51392 30.2567 5.46158C30.2567 4.41524 29.7488 3.46639 28.9675 2.62931L28.9557 2.61671L28.9435 2.60452C28.1112 1.77214 27.1748 1.21123 26.1315 1.04822C25.079 0.883766 24.0454 1.14426 23.0651 1.72089Z"
        strokeWidth="2"
      />
    </svg>
  )
}

export default QuoteIcon
