import * as React from 'react'

export interface UsersIconProps {
  className?: string
}

const UsersIcon: React.FCC<UsersIconProps> = ({ className }) => {
  return (
    // prettier-ignore
    <svg className={className} viewBox="0 0 24 24" fill="none" stroke="currentColor">
      <circle cx="9" cy="7" r="4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3 21V19C3 16.7909 4.79086 15 7 15H11C13.2091 15 15 16.7909 15 19V21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16 3.12988C17.7699 3.58305 19.0078 5.17787 19.0078 7.00488C19.0078 8.83189 17.7699 10.4267 16 10.8799" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M21 20.9999V18.9999C20.9896 17.1845 19.7578 15.6037 18 15.1499" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default UsersIcon
