import * as React from 'react'

export interface PixIconProps {
  className?: string
}

const PencilIcon: React.FCC<PixIconProps> = ({ className }) => {
  return (
    // prettier-ignore
    <svg className={className} viewBox="0 0 24 24" fill="currentColor" stroke="none">
      <path d="M18.1912 17.8571C17.3227 17.8571 16.5087 17.5231 15.8938 16.9083L12.5821 13.5965C12.3545 13.3689 11.9413 13.3689 11.7136 13.5965L8.38828 16.9219C7.77343 17.5367 6.95941 17.8707 6.09096 17.8707H5.43652L9.64395 22.0782C10.9528 23.387 13.0893 23.387 14.3982 22.0782L18.618 17.8584L18.1912 17.8571Z" />
      <path d="M6.07722 6.13022C6.94567 6.13022 7.7597 6.46424 8.37454 7.07908L11.6999 10.4044C11.9399 10.6444 12.3271 10.6444 12.5684 10.4044L15.8937 7.09269C16.5086 6.47785 17.3226 6.14383 18.191 6.14383H18.5919L14.3721 1.92403C13.0632 0.615166 10.9267 0.615166 9.61784 1.92403L5.40918 6.13022H6.07722Z" />
      <path d="M22.0766 9.62997L19.5257 7.07904C19.4725 7.10625 19.4057 7.11863 19.3389 7.11863H18.1772C17.576 7.11863 16.9884 7.35863 16.5752 7.78667L13.2622 11.0984C12.9554 11.4052 12.5409 11.566 12.1401 11.566C11.7257 11.566 11.3249 11.4052 11.0181 11.0984L7.69269 7.77306C7.26589 7.34625 6.67826 7.10502 6.09063 7.10502H4.66176C4.59496 7.10502 4.54176 7.09141 4.48857 7.06543L1.92403 9.62997C0.615166 10.9388 0.615166 13.0753 1.92403 14.3842L4.47496 16.9351C4.52816 16.9079 4.58135 16.8955 4.64816 16.8955H6.07702C6.67826 16.8955 7.26589 16.6555 7.67908 16.2275L11.0044 12.9021C11.6057 12.3009 12.6609 12.3009 13.2609 12.9021L16.5727 16.2139C16.9995 16.6407 17.5871 16.8819 18.1748 16.8819H19.3364C19.4032 16.8819 19.4564 16.8955 19.5232 16.9215L22.0741 14.3706C23.3855 13.0617 23.3855 10.9388 22.0766 9.62997Z" />
    </svg>
  )
}

export default PencilIcon
