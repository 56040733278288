'use client'

import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

import * as Icon from '@/components/core/internicons'
import LogoImage from '@/public/logo_eos_us_white.png'
import { env } from '@/utils/envs'

const UnAuthHeader: React.FC = () => {
  return (
    <header className="sticky top-0 z-40 flex h-24 items-center justify-between border-t-2 border-warning-300 bg-primary-300 px-6">
      <span className="flex items-center">
        <Link href={env.NEXT_PUBLIC_WEBSITE}>
          {env.LANGUAGE === 'pt-br' ? (
            <Icon.Logo fill="white" />
          ) : (
            <div className="max-w-[140px]">
              <Image alt="Logo Eos Solar Loan" src={LogoImage} />
            </div>
          )}
        </Link>
      </span>
    </header>
  )
}

export default UnAuthHeader
