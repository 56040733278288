import React from 'react'

interface HelocProps {
  className?: string
}

function Heloc({ className }: HelocProps) {
  return (
    <svg fill="none" viewBox="0 0 34 40" className={className}>
      <path
        stroke="#062840"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.667"
        d="M7.167 16H4.5l12-12 12 12h-2.667M7.167 16v9.333A2.667 2.667 0 009.833 28H13.7m12.133-12v3.2"
      ></path>
      <path
        stroke="#062840"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.667"
        d="M12.5 28v-8a2.667 2.667 0 012.667-2.666h2.666A2.667 2.667 0 0120.5 20"
      ></path>
      <circle
        cx="23.833"
        cy="30"
        r="7.5"
        stroke="#3085E8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.667"
      ></circle>
      <path
        stroke="#3085E8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.667"
        d="M26.167 27.5a1.667 1.667 0 00-1.5-.833H23A1.667 1.667 0 0023 30h1.667a1.667 1.667 0 110 3.333H23c-.616.021-1.192-.3-1.5-.833"
      ></path>
      <path
        fill="#3085E8"
        d="M24.667 25A.833.833 0 1023 25h1.667zM23 26.667a.833.833 0 001.667 0H23zm1.667 6.666a.833.833 0 00-1.667 0h1.667zM23 35a.833.833 0 001.667 0H23zm0-10v1.667h1.667V25H23zm0 8.333V35h1.667v-1.667H23z"
      ></path>
    </svg>
  )
}

export default Heloc
