import * as React from 'react'

export interface TextFileIconProps {
  className?: string
}

const TextFileIcon: React.FCC<TextFileIconProps> = ({ className }) => {
  return (
    // prettier-ignore
    <svg className={className} viewBox="0 0 32 32" stroke="currentColor" fill="none">
      <path d="M18.666 4V9.33333C18.666 10.0697 19.263 10.6667 19.9993 10.6667H25.3327"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M22.666 28H9.33268C7.85992 28 6.66602 26.8061 6.66602 25.3333V6.66667C6.66602 5.19391 7.85992 4 9.33268 4H18.666L25.3327 10.6667V25.3333C25.3327 26.8061 24.1388 28 22.666 28Z"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12 11.9997H13.3333"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12 17.3337H20"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12 22.6667H20"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default TextFileIcon
