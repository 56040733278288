import React from 'react'

interface HomeshareProps {
  className?: string
}

function Homeshare({ className }: HomeshareProps) {
  return (
    <svg fill="none" viewBox="0 0 34 40" className={className}>
      <path
        stroke="#062840"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.667"
        d="M6.833 16H4.167l12-12 12 12H25.5M6.833 16v9.333A2.667 2.667 0 009.5 28h3.867M25.5 16v3.2"
      ></path>
      <path
        stroke="#062840"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.667"
        d="M12.167 28v-8a2.667 2.667 0 012.666-2.666H17.5A2.667 2.667 0 0120.167 20"
      ></path>
      <path
        stroke="#3085E8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.667"
        d="M22.667 22.668a7.5 7.5 0 109 9 .833.833 0 00-.834-.834h-5.666c-.92 0-1.667-.746-1.667-1.666v-5.834a.75.75 0 00-.833-.666"
      ></path>
      <path
        stroke="#3085E8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.667"
        d="M26.833 22.917a7.5 7.5 0 014.584 4.583h-3.75a.833.833 0 01-.834-.833v-3.75"
      ></path>
    </svg>
  )
}

export default Homeshare
