import * as React from 'react'

export interface MinusIconProps {
  className?: string
}

const ToolIcon: React.FCC<MinusIconProps> = ({ className }) => {
  return (
    // prettier-ignore
    <svg className={className} viewBox="0 0 24 24" stroke='currentColor' fill="none">
      <path d="M5 12H19" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default ToolIcon
