import * as React from 'react'

export interface PencilIconProps {
  className?: string
}

const PencilIcon: React.FCC<PencilIconProps> = ({ className }) => {
  return (
    // prettier-ignore
    <svg className={className} viewBox="0 0 24 24" fill="none" stroke="currentColor">
      <path d="M4 20.0001H8L18.5 9.50006C19.6046 8.39549 19.6046 6.60463 18.5 5.50006C17.3954 4.39549 15.6046 4.39549 14.5 5.50006L4 16.0001V20.0001"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.5 6.5L17.5 10.5"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default PencilIcon
