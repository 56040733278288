import * as React from 'react'

export interface BankIconProps {
  className?: string
}

const BankIcon: React.FCC<BankIconProps> = ({ className }) => {
  return (
    // prettier-ignore
    <svg className={className} stroke='currentColor' viewBox="0 0 24 24" fill="none">
      <path d="M3 21H21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3 10H21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5 6L12 3L19 6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4 10V21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20 10V21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8 14V17" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12 14V17" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16 14V17" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default BankIcon
