import * as React from 'react'

export interface MailboxIconProps {
  className?: string
}

const MailboxIcon: React.FCC<MailboxIconProps> = ({ className }) => {
  return (
    // prettier-ignore
    <svg className={className} viewBox="0 0 56 56" fill="none">
      <path d="M28 25.6667V7H37.3333L42 11.6667L37.3333 16.3333H28" stroke="#FFA526" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14 34.9997H16.3333" stroke="#FFA526" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path stroke="#3085E8" d="M23.3333 49.0003V33.8337C23.3333 29.3233 19.677 25.667 15.1667 25.667C10.6563 25.667 7 29.3233 7 33.8337V49.0003H49V35.0003C49 29.8457 44.8213 25.667 39.6667 25.667H15.1667" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default MailboxIcon
