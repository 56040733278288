import * as React from 'react'

export interface MapIconProps {
  className?: string
}

const MapIcon: React.FCC<MapIconProps> = ({ className }) => {
  return (
    // prettier-ignore
    <svg className={className} width="17" height="17" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 17L13 15V9.858C14.721 9.411 16 7.858 16 6C16 3.794 14.206 2 12 2C9.794 2 8 3.794 8 6C8 7.858 9.279 9.411 11 9.858V15L12 17Z" fill="currentColor"/>
    <path d="M16.267 10.563L15.734 12.491C18.325 13.207 20 14.584 20 16C20 17.892 16.715 20 12 20C7.285 20 4 17.892 4 16C4 14.584 5.675 13.207 8.267 12.49L7.734 10.562C4.197 11.54 2 13.623 2 16C2 19.364 6.393 22 12 22C17.607 22 22 19.364 22 16C22 13.623 19.803 11.54 16.267 10.563V10.563Z" fill="currentColor"/>
    </svg>
  )
}

export default MapIcon
