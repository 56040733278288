import * as React from 'react'

export interface HistoricIconProps {
  className?: string
}

const HistoricIcon: React.FCC<HistoricIconProps> = ({ className }) => {
  return (
    // prettier-ignore
    <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 8V12L14 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2.05593 10.8987C1.99996 11.4482 2.4 11.939 2.94944 11.9949C3.49888 12.0509 3.98967 11.6509 4.04563 11.1014L2.05593 10.8987ZM4.49008 14.657C4.30059 14.1382 3.72643 13.8713 3.20767 14.0608C2.68892 14.2503 2.42199 14.8244 2.61148 15.3432L4.49008 14.657ZM2.05078 20.0001C2.05078 20.5524 2.4985 21.0001 3.05078 21.0001C3.60307 21.0001 4.05078 20.5524 4.05078 20.0001H2.05078ZM3.05078 15.0001V14.0001C2.4985 14.0001 2.05078 14.4478 2.05078 15.0001H3.05078ZM8.05078 16.0001C8.60307 16.0001 9.05078 15.5524 9.05078 15.0001C9.05078 14.4478 8.60307 14.0001 8.05078 14.0001V16.0001ZM4.04563 11.1014C4.45802 7.05293 7.84391 3.95912 11.9131 3.91264L11.8902 1.91277C6.80377 1.97087 2.57141 5.83813 2.05593 10.8987L4.04563 11.1014ZM11.9131 3.91264C15.9823 3.86616 19.4379 6.88183 19.9427 10.9198L21.9272 10.6718C21.2963 5.62425 16.9767 1.85467 11.8902 1.91277L11.9131 3.91264ZM19.9427 10.9198C20.4474 14.9579 17.8404 18.7314 13.885 19.688L14.3551 21.6319C19.2994 20.4362 22.5582 15.7193 21.9272 10.6718L19.9427 10.9198ZM13.885 19.688C9.92959 20.6445 5.88633 18.4794 4.49008 14.657L2.61148 15.3432C4.3568 20.1212 9.41087 22.8277 14.3551 21.6319L13.885 19.688ZM4.05078 20.0001V15.0001H2.05078V20.0001H4.05078ZM3.05078 16.0001H8.05078V14.0001H3.05078V16.0001Z" fill="currentColor"/>
    </svg>
  )
}

export default HistoricIcon
