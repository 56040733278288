import * as React from 'react'

export interface AlertCircleIconProps {
  className?: string
}

const ArrowsSortIcon: React.FCC<AlertCircleIconProps> = ({ className }) => {
  return (
    // prettier-ignore
    <svg className={className} viewBox="0 0 16 16" fill="currentColor" stroke='none'>
      <path d="M1.46967 5.46968C1.17678 5.76257 1.17678 6.23745 1.46967 6.53034C1.76256 6.82323 2.23744 6.82323 2.53033 6.53034L1.46967 5.46968ZM4.66667 3.33334L5.197 2.80301C4.9041 2.51012 4.42923 2.51012 4.13634 2.80301L4.66667 3.33334ZM6.803 6.53034C7.0959 6.82323 7.57077 6.82323 7.86366 6.53034C8.15656 6.23745 8.15656 5.76257 7.86366 5.46968L6.803 6.53034ZM5.41667 3.33334C5.41667 2.91913 5.08088 2.58334 4.66667 2.58334C4.25245 2.58334 3.91667 2.91913 3.91667 3.33334H5.41667ZM3.91667 12.6667C3.91667 13.0809 4.25245 13.4167 4.66667 13.4167C5.08088 13.4167 5.41667 13.0809 5.41667 12.6667H3.91667ZM2.53033 6.53034L5.197 3.86367L4.13634 2.80301L1.46967 5.46968L2.53033 6.53034ZM4.13634 3.86367L6.803 6.53034L7.86366 5.46968L5.197 2.80301L4.13634 3.86367ZM3.91667 3.33334V12.6667H5.41667V3.33334H3.91667Z" />
      <path d="M14.5302 10.5303C14.8231 10.2374 14.8231 9.76257 14.5302 9.46968C14.2373 9.17679 13.7624 9.17679 13.4695 9.46968L14.5302 10.5303ZM11.3332 12.6667L10.8028 13.197C10.9435 13.3377 11.1343 13.4167 11.3332 13.4167C11.5321 13.4167 11.7228 13.3377 11.8635 13.197L11.3332 12.6667ZM9.19683 9.46968C8.90394 9.17679 8.42907 9.17679 8.13617 9.46968C7.84328 9.76257 7.84328 10.2374 8.13617 10.5303L9.19683 9.46968ZM10.5832 12.6667C10.5832 13.0809 10.919 13.4167 11.3332 13.4167C11.7474 13.4167 12.0832 13.0809 12.0832 12.6667H10.5832ZM12.0832 3.33334C12.0832 2.91913 11.7474 2.58334 11.3332 2.58334C10.919 2.58334 10.5832 2.91913 10.5832 3.33334H12.0832ZM13.4695 9.46968L10.8028 12.1363L11.8635 13.197L14.5302 10.5303L13.4695 9.46968ZM11.8635 12.1363L9.19683 9.46968L8.13617 10.5303L10.8028 13.197L11.8635 12.1363ZM12.0832 12.6667V3.33334H10.5832V12.6667H12.0832Z" />
    </svg>
  )
}

export default ArrowsSortIcon
