import * as React from 'react'

export interface AlertCircleIconProps {
  className?: string
}

const AlertCircleIcon: React.FCC<AlertCircleIconProps> = ({ className }) => {
  return (
    // prettier-ignore
    <svg className={className} viewBox="0 0 24 24" fill="none" stroke='currentColor'>
      <circle cx="12" cy="12" r="9"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12 8V12"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.0009 16H12.0109" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default AlertCircleIcon
