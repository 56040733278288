import * as React from 'react'

export interface UserCheckIconProps {
  className?: string
}

const UserCheckIcon: React.FCC<UserCheckIconProps> = ({ className }) => {
  return (
    // prettier-ignore
    <svg className={className} viewBox="0 0 24 24" fill="none" stroke="currentColor">
      <circle cx="9" cy="7" r="4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3 21V19C3 16.7909 4.79086 15 7 15H11C13.2091 15 15 16.7909 15 19V21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16 11L18 13L22 9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default UserCheckIcon
