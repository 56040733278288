import * as React from 'react'

export interface ToolIconProps {
  className?: string
}

const ToolIcon: React.FCC<ToolIconProps> = ({ className }) => {
  return (
    // prettier-ignore
    <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.00002 10H10V7.00002L6.50002 3.50002C8.79447 2.40421 11.5305 2.87363 13.3284 4.67159C15.1264 6.46955 15.5958 9.20557 14.5 11.5L20.5 17.5C21.3284 18.3284 21.3284 19.6716 20.5 20.5C19.6716 21.3284 18.3284 21.3284 17.5 20.5L11.5 14.5C9.20557 15.5958 6.46955 15.1264 4.67159 13.3284C2.87363 11.5305 2.40421 8.79447 3.50002 6.50002L7.00002 10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default ToolIcon
