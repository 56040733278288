import React from 'react'

interface SolarProps {
  className?: string
}

function Solar({ className }: SolarProps) {
  return (
    <svg fill="none" viewBox="0 0 34 40" className={className}>
      <path
        stroke="#062840"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.667"
        d="M25.833 18.28l-7.11-5.53a3.555 3.555 0 00-4.366 0l-7.112 5.53a3.553 3.553 0 00-1.372 2.807v9.6a2.667 2.667 0 002.667 2.667h16a2.667 2.667 0 002.667-2.667v-9.6a3.554 3.554 0 00-1.374-2.807"
      ></path>
      <path
        stroke="#062840"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.667"
        d="M13.833 22.667H19.166V28H13.833z"
      ></path>
      <path
        stroke="#3085E8"
        strokeLinecap="round"
        strokeWidth="1.667"
        d="M16.333 5.3l1.183.923M29.436 15.882l1.184.922M30.48 10.365l1.549-.27M22.634 4.069l-.115-1.57M29.754 3.47l-1.076 1.38M26.805 14.913a5 5 0 10-7.887-6.147"
      ></path>
    </svg>
  )
}

export default Solar
