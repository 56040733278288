import * as React from 'react'

export interface CalendarTimeIconProps {
  className?: string
}

const CalendarTimeIcon: React.FCC<CalendarTimeIconProps> = ({ className }) => {
  return (
    // prettier-ignore
    <svg className={className}  viewBox="0 0 32 32" stroke='currentColor' fill="none">
      <path d="M15.7267 28.0003H6.66667C5.19391 28.0003 4 26.8064 4 25.3337V9.33366C4 7.8609 5.19391 6.66699 6.66667 6.66699H22.6667C24.1394 6.66699 25.3333 7.8609 25.3333 9.33366V14.667"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <circle cx="23.9993" cy="24.0003" r="5.33333"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20.0007 4V9.33333"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.33268 4V9.33333"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4 14.6667H25.3333"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M24 21.9951V24.0005L25.3333 25.3338"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default CalendarTimeIcon
