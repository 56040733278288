import * as React from 'react'

export interface FileDollarProps {
  className?: string
}

const FileDollar: React.FCC<FileDollarProps> = ({ className }) => {
  return (
    // prettier-ignore
    <svg className={className} viewBox="0 0 24 24" fill="none" stroke="currentColor">
      <path d="M14 3V7C14 7.55228 14.4477 8 15 8H19" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M17 21H7C5.89543 21 5 20.1046 5 19V5C5 3.89543 5.89543 3 7 3H14L19 8V19C19 20.1046 18.1046 21 17 21Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14 11H11.5C10.6716 11 10 11.6716 10 12.5C10 13.3284 10.6716 14 11.5 14H12.5C13.3284 14 14 14.6716 14 15.5C14 16.3284 13.3284 17 12.5 17H10" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path stroke="none" d="M13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17H13ZM11 18C11 18.5523 11.4477 19 12 19C12.5523 19 13 18.5523 13 18H11ZM13 10C13 9.44772 12.5523 9 12 9C11.4477 9 11 9.44772 11 10H13ZM11 11C11 11.5523 11.4477 12 12 12C12.5523 12 13 11.5523 13 11H11ZM11 17V18H13V17H11ZM11 10V11H13V10H11Z" fill="currentColor"/>
    </svg>
  )
}

export default FileDollar
