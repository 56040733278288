import * as React from 'react'

export interface ZoomMoneyIconProps {
  className?: string
}

const ZoomMoneyIcon: React.FCC<ZoomMoneyIconProps> = ({ className }) => {
  return (
    // prettier-ignore
    <svg className={className} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="33.3333" cy="33.3333" r="23.3333" stroke="#3085E8" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M70 70L50 50" stroke="#3085E8" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M39.9993 23.3335H31.666C28.9046 23.3335 26.666 25.5721 26.666 28.3335C26.666 31.0949 28.9046 33.3335 31.666 33.3335H34.9993C37.7608 33.3335 39.9993 35.5721 39.9993 38.3335C39.9993 41.0949 37.7608 43.3335 34.9993 43.3335H26.666" stroke="#3085E8" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M34.834 43.3333C34.834 42.5049 34.1624 41.8333 33.334 41.8333C32.5056 41.8333 31.834 42.5049 31.834 43.3333H34.834ZM31.834 46.6667C31.834 47.4951 32.5056 48.1667 33.334 48.1667C34.1624 48.1667 34.834 47.4951 34.834 46.6667H31.834ZM34.834 20C34.834 19.1716 34.1624 18.5 33.334 18.5C32.5056 18.5 31.834 19.1716 31.834 20H34.834ZM31.834 23.3333C31.834 24.1618 32.5056 24.8333 33.334 24.8333C34.1624 24.8333 34.834 24.1618 34.834 23.3333H31.834ZM31.834 43.3333V46.6667H34.834V43.3333H31.834ZM31.834 20V23.3333H34.834V20H31.834Z" fill="#3085E8"/>
</svg>
  )
}

export default ZoomMoneyIcon
