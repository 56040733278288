import * as React from 'react'

export interface TableIconProps {
  className?: string
}

const TableIcon: React.FCC<TableIconProps> = ({ className }) => {
  return (
    // prettier-ignore
    <svg className={className} viewBox="0 0 24 24" fill="none" stroke="currentColor">
      <rect x="4" y="4" width="16" height="16" rx="2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4 10H20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10 4V20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default TableIcon
