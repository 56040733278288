import * as React from 'react'

export interface SuccessIconProps {
  className?: string
}

const SuccessIcon: React.FCC<SuccessIconProps> = ({ className }) => {
  return (
    // prettier-ignore
    <svg className={className} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="40" cy="40" r="30" stroke="#3085E8" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M30 40.0002L36.6667 46.6668L50 33.3335" stroke="#FFA526" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default SuccessIcon
