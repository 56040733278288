import * as React from 'react'

export interface AlertIconProps {
  className?: string
}

const MaintenanceIcon: React.FCC<AlertIconProps> = () => {
  return (
    // prettier-ignore
    <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="68" r="8" stroke="#3085E8" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
      <circle cx="52" cy="68" r="8" stroke="#3085E8" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M52 76H16" stroke="#3085E8" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M16 60H52" stroke="#3085E8" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M32 48V28H40C46.6274 28 52 33.3726 52 40V60" stroke="#3085E8" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M20 60V52C20 49.7909 21.7909 48 24 48H52" stroke="#3085E8" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M84.48 39.52L72 20L52 40" stroke="#3085E8" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M84.48 39.52C87.9075 42.9518 88.9321 48.1095 87.0766 52.5908C85.221 57.0721 80.8503 59.9957 76 60C72.8185 59.9972 69.7683 58.7311 67.52 56.48L84.48 39.52V39.52Z" stroke="#FFA526" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export default MaintenanceIcon
