import * as React from 'react'

export interface FileNewIconProps {
  className?: string
}

const FileNewIcon: React.FCC<FileNewIconProps> = ({ className }) => {
  return (
    // prettier-ignore
    <svg viewBox="0 0 48 48" fill="none" className={className}>
      <path d="M28 6V14C28 15.1046 28.8954 16 30 16H38" stroke="#3085E8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M34 42H14C11.7909 42 10 40.2091 10 38V10C10 7.79086 11.7909 6 14 6H28L38 16V38C38 40.2091 36.2091 42 34 42Z" stroke="#3085E8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M24 22V34" stroke="#FFA526" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18 28H30" stroke="#FFA526" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default FileNewIcon
