import React from 'react'

interface HomeImprovementProps {
  className?: string
}

function HomeImprovement({ className }: HomeImprovementProps) {
  return (
    <svg fill="none" viewBox="0 0 35 40" className={className}>
      <path
        stroke="#062840"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.667"
        d="M13.233 26.687h-4.36a2.667 2.667 0 01-2.666-2.667v-9.6c0-1.097.506-2.133 1.372-2.807l7.112-5.53a3.555 3.555 0 014.365 0l7.11 5.53a3.554 3.554 0 011.374 2.807v4.8"
      ></path>
      <path
        stroke="#062840"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.667"
        d="M19.5 18.667V16h-5.333v5.333h2.666"
      ></path>
      <path
        stroke="#3085E8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.667"
        d="M16.667 37.5H20l10.833-10.833a2.357 2.357 0 00-3.333-3.334L16.667 34.167V37.5M26.25 24.583l3.333 3.334"
      ></path>
      <path
        stroke="#3085E8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.667"
        d="M24.167 26.667L20 22.5l-3.333 3.333L20.833 30M20 26.667l-1.25 1.25M27.5 30l4.167 4.167-3.334 3.333-4.166-4.167M27.5 34.167l-1.25 1.25"
      ></path>
    </svg>
  )
}

export default HomeImprovement
