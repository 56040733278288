import * as React from 'react'

export interface WhatsappIconProps {
  className?: string
}

const WhatsappIcon: React.FCC<WhatsappIconProps> = ({ className }) => {
  return (
    // prettier-ignore
    <svg className={className} viewBox="0 0 24 24" stroke='currentColor' fill="none">
      <path d="M3 21L4.65 17.2C2.03818 13.4924 2.59303 8.42067 5.94471 5.36549C9.29638 2.31032 14.3977 2.22626 17.8482 5.16935C21.2987 8.11243 22.0203 13.1631 19.5321 16.9547C17.0439 20.7464 12.1232 22.0943 8.05 20.1L3 21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9 10C9 10.2761 9.22386 10.5 9.5 10.5C9.77614 10.5 10 10.2761 10 10V9C10 8.72386 9.77614 8.5 9.5 8.5C9.22386 8.5 9 8.72386 9 9V10C9 12.7614 11.2386 15 14 15H15C15.2761 15 15.5 14.7761 15.5 14.5C15.5 14.2239 15.2761 14 15 14H14C13.7239 14 13.5 14.2239 13.5 14.5C13.5 14.7761 13.7239 15 14 15" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default WhatsappIcon
